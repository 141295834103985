export const CATEGORY_OTHER = 1;
export const CATEGORY_VEHICLES_AND_SUPPLIES = 2;
export const CATEGORY_VEHICLE_SUPPLIES = 3;
export const CATEGORY_REAL_ESTATE = 4;
export const CATEGORY_LIGHT_MACHINERY_AND_SUPPLIES = 5;
export const CATEGORY_TOOLS = 6;
export const CATEGORY_FURNITURE_AND_KITCHEN_EQUIPMENT = 8;
export const CATEGORY_ELECTRONICS = 10;
export const CATEGORY_JEWELRY_CLOCKS_AND_ANTIQUES = 11;
export const CATEGORY_INDUSTRIAL_MACHINERY = 12;
export const CATEGORY_BULK_GOODS = 13;
export const CATEGORY_PASSENGER_CAR = 14;
export const CATEGORY_VAN = 15;
export const CATEGORY_TRUCK = 16;
export const CATEGORY_BOAT = 17;
export const CATEGORY_MOTORCYCLE = 18;
export const CATEGORY_CARAVAN_AND_TRAILER = 19;
export const CATEGORY_OTHER_VEHICLE = 20;
export const CATEGORY_SNOWMOBILE = 21;
export const CATEGORY_APARTMENT = 22;
export const CATEGORY_TIMESHARE = 23;
export const CATEGORY_PREMISES = 24;
export const CATEGORY_PLOT = 25;
export const CATEGORY_CONSTRUCTION_MACHINERY = 26;
export const CATEGORY_FARMING_MACHINERY = 27;
export const CATEGORY_FOREST_MACHINERY = 28;
export const CATEGORY_OTHER_MACHINERY = 29;
export const CATEGORY_CONSTRUCTION_SUPPLIES = 30;
export const CATEGORY_HOUSEHOLD_APPLIANCES = 31;
export const CATEGORY_FURNITURE = 32;
export const CATEGORY_IT_EQUIPMENT = 33;
export const CATEGORY_OTHER_ELECTRONICS = 34;
export const CATEGORY_FREE_TIME = 35;
export const CATEGORY_MACHINERY_SUPPLIES = 36;
export const CATEGORY_VACATION_HOMES = 37;
export const CATEGORY_GARAGES = 38;
export const CATEGORY_HOBBY_SUPPLIES = 39;
export const CATEGORY_BICYCLES = 40;
export const CATEGORY_FISHING_AND_HUNTING = 41;
export const CATEGORY_INSTRUMENTS_AND_MUSIC = 42;
export const CATEGORY_GARDEN = 43;
export const CATEGORY_GARDEN_FURNITURE = 44;
export const CATEGORY_OUTDOOR_LIGHTS = 45;
export const CATEGORY_YARD_DECORATION = 46;
export const CATEGORY_YARD_BUILDINGS = 47;
export const CATEGORY_TOOLS_AND_TOOLSETS = 48;
export const CATEGORY_HANDCRAFT_TOOLS = 49;
export const CATEGORY_CONSTRUCTION = 50;
export const CATEGORY_BATHROOM_RENOVATION = 51;
export const CATEGORY_KITCHEN_RENOVATION = 52;
export const CATEGORY_HVAC_SUPPLIES = 53;
export const CATEGORY_ELECTRONICS_SUPPLIES = 54;
export const CATEGORY_TABLEWARE = 55;
export const CATEGORY_DECOR = 56;
export const CATEGORY_ANTIQUE = 57;
export const CATEGORY_AUDIO = 58;
export const CATEGORY_TV = 59;
export const CATEGORY_PHONE_AND_IT_ACCESSORIES = 60;
export const CATEGORY_COLLECTIBLES = 61;
export const CATEGORY_OTHER_COLLECTIBLES = 62;
export const CATEGORY_HEAVY_EQUIPMENT = 63;
export const CATEGORY_HEAVY_EQUIPMENT_SPARE_PARTS = 64;
export const CATEGORY_VEHICLE_SPARE_PARTS = 65;

/**
 * CategoryStaticData is information that exists solely in the frontend.
 * The backend and the API it provides knows nothing about category icons
 * or slugs for category pages.
 */
export interface CategoryStaticData {
  readonly icon: React.ComponentType;
  readonly slug: string;
}

/**
 * CategoryDynamicData is information we get from the categories with counts endpoint.
 * Entry counts and even names of categories can change.
 */
export interface CategoryDynamicData {
  readonly id: number;
  readonly name: string;
  readonly htmlName: string;
  readonly children: Record<number, CategoryDynamicData>;
  readonly parentId: number | null;
  readonly entryCount: number;
  readonly order: number;
}

export interface CategoriesDynamicData {
  categories: CategoryDynamicData[];
  counts: CategoryCounts;
}

export interface CategoryCounts {
  Nettihuutokauppa: number;
  ulosotto: number;
  kuolinpesa: number;
  metsahallitus: number;
  julkinen_sektori: number;
  puolustusvoimat: number;
  konkurssipesat: number;
  rahoitusyhtiot: number;
  auctions: number;
  endingToday: number;
}
