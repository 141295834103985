import React from 'react';
import { Box } from '@mezzoforte/forge';
import styled from '@emotion/styled';
import { useSession } from '@/hooks/useSession';
import { useCompanySelection } from '@/hooks/useCompanySelection';
import { useShouldShowCompanySelector } from '@/hooks/useShouldShowCompanySelector';
import { CompanyRow } from '@/components/Header/UserGreeting/CompanyRow';
import { CompanySelector } from '@/components/Header/UserGreeting/CompanySelector';

const UserGreetingBox = styled(Box)`
  color: black;
  display: block;
`;

export function UserGreeting() {
  const { currentUser } = useSession();
  const { selectedCompany } = useCompanySelection();
  const { shouldShowCompanySelector } = useShouldShowCompanySelector();

  if (!shouldShowCompanySelector) {
    return (
      <Box as="span" color="subdued" display="block" px={4} py={2}>
        {currentUser.data?.user?.firstName ? `Hei, ${currentUser.data?.user?.firstName}` : 'Hei!'}
      </Box>
    );
  }

  return (
    <UserGreetingBox>
      <Box borderBottomWidth="1px" borderBottomStyle="solid" borderColor="lineBorder">
        <Box as="span" display="block" px={4}>
          <Box color="subdued" pb={2}>
            Olet kirjautunut käyttäjänä
          </Box>
          {selectedCompany && <CompanyRow company={selectedCompany} />}
        </Box>
      </Box>
      <Box borderBottomWidth="1px" borderBottomStyle="solid" borderColor="lineBorder" mb={2}>
        <CompanySelector />
      </Box>
    </UserGreetingBox>
  );
}
