'use client';

import React, { useContext } from 'react';
import { Box, Button, Flex, Heading } from '@mezzoforte/forge';
import { X } from '@mezzoforte/forge-icons';
import { ShowResponsive } from '@/components/Responsive/ShowResponsive';
import CategoryAccordion from '@/components/Category/CategoryAccordion';
import { NavItemContext } from '@/features/SiteHeader/NavItemContext';

function CategoryMenu() {
  const { close } = useContext(NavItemContext);
  return (
    <Box m={3}>
      <ShowResponsive xlHide>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading variant="h3" pb={3}>
            Osastot
          </Heading>
          <Button icon={<X />} aria-label="Sulje" onClick={close} />
        </Flex>
      </ShowResponsive>
      <CategoryAccordion />
      <ShowResponsive xlHide>
        <Flex justifyContent="flex-end" mt={3}>
          <Button aria-label="Sulje" onClick={close}>
            Sulje
          </Button>
        </Flex>
      </ShowResponsive>
    </Box>
  );
}
export default CategoryMenu;
