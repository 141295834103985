import * as c from '@/types/Category';

export const categorySlugs = new Map<number, string>([
  [c.CATEGORY_OTHER, 'muut'],
  [c.CATEGORY_VEHICLES_AND_SUPPLIES, 'ajoneuvot-ja-tarvikkeet'],
  [c.CATEGORY_VEHICLE_SUPPLIES, 'ajoneuvotarvikkeet'],
  [c.CATEGORY_REAL_ESTATE, 'asunnot-mokit-toimitilat-ja-tontit'],
  [c.CATEGORY_LIGHT_MACHINERY_AND_SUPPLIES, 'puutarhakoneet-ja-leikkurit'],
  [c.CATEGORY_TOOLS, 'sahkotyokalut-ja-akkutyokalusarjat'],
  [c.CATEGORY_FURNITURE_AND_KITCHEN_EQUIPMENT, 'sisustaminen-ja-koti'],
  [c.CATEGORY_ELECTRONICS, 'elektroniikka'],
  [c.CATEGORY_JEWELRY_CLOCKS_AND_ANTIQUES, 'kellot-ja-korut'],
  [c.CATEGORY_INDUSTRIAL_MACHINERY, 'tyokoneet'],
  [c.CATEGORY_BULK_GOODS, 'tukkuerat'],
  [c.CATEGORY_PASSENGER_CAR, 'henkiloautot'],
  [c.CATEGORY_VAN, 'pakettiautot'],
  [c.CATEGORY_TRUCK, 'kevytkuorma-autot'],
  [c.CATEGORY_BOAT, 'veneet'],
  [c.CATEGORY_MOTORCYCLE, 'moottoripyorat-ja-mopot'],
  [c.CATEGORY_CARAVAN_AND_TRAILER, 'perakarryt-ja-asuntovaunut'],
  [c.CATEGORY_OTHER_VEHICLE, 'muut-ajoneuvot'],
  [c.CATEGORY_SNOWMOBILE, 'moottorikelkat-ja-monkijat'],
  [c.CATEGORY_APARTMENT, 'asunnot'],
  [c.CATEGORY_TIMESHARE, 'lomaosakkeet'],
  [c.CATEGORY_PREMISES, 'liike-ja-toimitilat'],
  [c.CATEGORY_PLOT, 'tontit-maa-ja-metsatilat'],
  [c.CATEGORY_CONSTRUCTION_MACHINERY, 'maarakennuskoneet'],
  [c.CATEGORY_FARMING_MACHINERY, 'maatalouskoneet'],
  [c.CATEGORY_FOREST_MACHINERY, 'metsakoneet'],
  [c.CATEGORY_OTHER_MACHINERY, 'muut-tyokoneet'],
  [c.CATEGORY_CONSTRUCTION_SUPPLIES, 'rakennusmateriaalit'],
  [c.CATEGORY_HOUSEHOLD_APPLIANCES, 'kodinkoneet-ja-sahkolaitteet'],
  [c.CATEGORY_FURNITURE, 'huonekalut-ja-kalusteet'],
  [c.CATEGORY_IT_EQUIPMENT, 'tietokoneet-tabletit-ja-puhelimet'],
  [c.CATEGORY_OTHER_ELECTRONICS, 'muu-viihde-elektroniikka'],
  [c.CATEGORY_FREE_TIME, 'urheiluun-ja-ulkoiluun'],
  [c.CATEGORY_MACHINERY_SUPPLIES, 'tyokonetarvikkeet'],
  [c.CATEGORY_VACATION_HOMES, 'loma-asunnot-ja-mokit'],
  [c.CATEGORY_GARAGES, 'autotallit-siirrettavat-rakennelmat-ja-muut'],
  [c.CATEGORY_HOBBY_SUPPLIES, 'harrastusvalineet-ja-vapaa-aika'],
  [c.CATEGORY_BICYCLES, 'polkupyorat-ja-pyoraily'],
  [c.CATEGORY_FISHING_AND_HUNTING, 'kalastustarvikkeet-ja-metsastystarvikkeet'],
  [c.CATEGORY_INSTRUMENTS_AND_MUSIC, 'soittimet-ja-muu-musiikki'],
  [c.CATEGORY_GARDEN, 'piha-ja-puutarha'],
  [c.CATEGORY_GARDEN_FURNITURE, 'puutarhakalusteet-ja-pihagrillit'],
  [c.CATEGORY_OUTDOOR_LIGHTS, 'ulkovalaisimet-ja-terassilammittimet'],
  [c.CATEGORY_YARD_DECORATION, 'pihakoristeet-ja-pihan-rakentaminen'],
  [c.CATEGORY_YARD_BUILDINGS, 'piharakennukset-ja-piha-aidat'],
  [c.CATEGORY_TOOLS_AND_TOOLSETS, 'tyokalut-ja-tyokalusarjat'],
  [c.CATEGORY_HANDCRAFT_TOOLS, 'kasityokalut-ja-kasityokalusarjat'],
  [c.CATEGORY_CONSTRUCTION, 'rakennustarvikkeet'],
  [c.CATEGORY_BATHROOM_RENOVATION, 'kylpyhuoneen-saunan-ja-wc-n-remontointi'],
  [c.CATEGORY_KITCHEN_RENOVATION, 'keittion-remontointi'],
  [c.CATEGORY_HVAC_SUPPLIES, 'lvi-tarvikkeet-ja-putket'],
  [c.CATEGORY_ELECTRONICS_SUPPLIES, 'sahkotarvikkeet-ja-sahkolaitteet'],
  [c.CATEGORY_TABLEWARE, 'astiastot-ja-aterimet'],
  [c.CATEGORY_DECOR, 'sisustus'],
  [c.CATEGORY_ANTIQUE, 'antiikki-ja-taide'],
  [c.CATEGORY_AUDIO, 'audio'],
  [c.CATEGORY_TV, 'tv'],
  [c.CATEGORY_PHONE_AND_IT_ACCESSORIES, 'puhelintarvikkeet-ja-tietokoneen-oheislaitteet'],
  [c.CATEGORY_COLLECTIBLES, 'keraily'],
  [c.CATEGORY_OTHER_COLLECTIBLES, 'muut-kerailyesineet'],
  [c.CATEGORY_HEAVY_EQUIPMENT, 'raskas-kalusto'],
  [c.CATEGORY_HEAVY_EQUIPMENT_SPARE_PARTS, 'raskaan-kaluston-varaosat'],
  [c.CATEGORY_VEHICLE_SPARE_PARTS, 'ajoneuvovaraosat'],
]);
