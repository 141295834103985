import React from 'react';
import { Box, useTokens } from '@mezzoforte/forge';
import styled from '@emotion/styled';
import { useSession } from '@/hooks/useSession';
import { useCompanySelection } from '@/hooks/useCompanySelection';
import { CompanyRow } from '@/components/Header/UserGreeting/CompanyRow';

const { colors } = useTokens.huutokaupat;

const CompanyRowBox = styled(Box)`
  :has(a:hover) {
    background-color: ${colors.backgroundGray};
  }

  a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
`;

export function CompanySelector() {
  const { currentUser } = useSession();
  const { selectedCompany, selectCompanyId } = useCompanySelection();

  if (!currentUser.data?.isAuthenticated) {
    return null;
  }

  const selectableCompanies = currentUser.data.companies.filter((company) => company.id !== selectedCompany?.id);

  return (
    <Box py={2}>
      {...selectableCompanies.map((company) => (
        <CompanyRowBox key={company.id}>
          <Box as="span" display="block" px={4}>
            <CompanyRow company={company} onSelect={selectCompanyId} />
          </Box>
        </CompanyRowBox>
      ))}
    </Box>
  );
}
