import { useSession } from '@/hooks/useSession';

// This hook is used to determine whether the company selector should be shown in the header.
// Initially it will only be shown if the user has more than one company.
// After every user has a KuHu company, we will show the company selector to everyone and this is no longer needed.
export const useShouldShowCompanySelector = () => {
  const { currentUser } = useSession();

  return {
    shouldShowCompanySelector: currentUser.data?.isAuthenticated && currentUser.data.companies.length > 1,
  };
};
