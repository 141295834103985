'use client';

import { NavItem } from '@/features/SiteHeader/MainNavigationItem';
import { useSession } from '@/hooks/useSession';
import { MainNavigationItemProps } from './index';
import { icons, menuItemsFromSlice } from './util';

export function NavItemDropdown({ slice }: MainNavigationItemProps) {
  const { currentUser } = useSession();
  return (
    <NavItem
      type="Dropdown"
      label={slice.primary.title ?? ''}
      icon={icons[slice.primary.icon ?? 'List']}
      items={menuItemsFromSlice(slice, currentUser)}
    />
  );
}
