export type MergeFn<L, R> = (left: L, right: R, result: (L | R)[]) => number;

/**
 * Takes two lists and merges them into one, picking the next one from either list
 * chosen by the passed merge function. mergeFn takes two arguments, the first elements
 * of both lists, and returns >0 or <0, similar to Array.prototype.sort's compare function.
 *
 * @param mergeFn compare function decides which array to pull from next
 * @param left first list of items
 * @param right second list of items
 * @returns merged list
 */
export const merge = <L, R = L>(mergeFn: MergeFn<L, R>, left: L[], right: R[]): (L | R)[] => {
  function rec(mergeFn: MergeFn<L, R>, l: L[], r: R[], result: (L | R)[]): (L | R)[] {
    const [lHead, ...lRest] = l;
    const [rHead, ...rRest] = r;

    if (!l.length) return [...result, ...r];
    if (!r.length) return [...result, ...l];
    if (mergeFn(lHead, rHead, result) <= 0) return rec(mergeFn, lRest, r, [...result, lHead]);
    return rec(mergeFn, l, rRest, [...result, rHead]);
  }

  return rec(mergeFn, left, right, []);
};

export function splitToChunks<T>(array: T[], chunks: number): T[][] {
  const chunkSize = Math.ceil(array.length / chunks);
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

/**
 * Splits an array of items on certain items that `matchFn` returns true for.
 * Matched items are removed from the result.
 * @example splitBy(i => i == 3, [1,2,3,4,5]) -> [[1, 2], [4, 5]]
 * @param matchFn If matchFn returns true, item is a separator item
 * @param array The list to be iterated
 */
export const splitBy = <TItem>(matchFn: (item: TItem) => boolean, array: TItem[]) =>
  array
    .reduce<TItem[][]>(
      (result, item) => {
        if (matchFn(item)) return [[], ...result];
        const [current, ...prevChunks] = result;
        return [[...current, item], ...prevChunks];
      },
      [[]]
    )
    .reverse()
    .filter((chunk) => chunk.length > 0);
